import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { Link, withPrefix } from "gatsby"
import { changeProgressBarOnScroll } from "../components/utils/index"

export default class FAQPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "FAQ")
  }

  render() {
    const { faqs } = pageData
    return (
      <Layout>
        <div className="faq-page">
          <HeadMeta metadata={pageData.metadata} />
          <ProgressBar />
          <section
            className="hero hero-landing bg-gradient-blue-green py-5"
            style={{ minHeight: "50vh" }}
          >
            <div className="container text-white">
              <header className="text-center mb-4">
                <h1>Frequently Asked Questions (F.A.Q)</h1>
                <p className="lead">
                  Can't find what you are looking for?
                  <br />
                  Check out our{" "}
                  <Link to={withPrefix("/support")}>
                    <u>Customer Care & Support </u>
                  </Link>
                  &nbsp;page.
                </p>
              </header>
            </div>
          </section>
          <section className="py-5">
            <div className="container">
              <Accordion>
                {faqs.map((faq, i) => (
                  <AccordionItem key={i}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <h3>{faq.question}</h3>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Frequently Asked Questions | Advanx Health",
    description:
      "Find the answers to some of the most frequently asked questions about Advanx Health's DNA tests and personalised services.",
    image: "https://www.advanxhealth.com/img/share/power-of-genes.png",
    url: "https://www.advanxhealth.com/faq",
  },
  faqs: [
    {
      question: "Do you need to draw blood from me?",
      answer:
        "No, this process does not involve drawing blood. We can obtain your DNA from the buccal epithelial (skin on your inner cheeks) cells and white blood cells found in your saliva.",
    },
    {
      question: "Do I need to go to Advanx Health to give my saliva sample?",
      answer:
        "No, once you've made a purchase, we'll send you a saliva collection kit and you can provide your saliva sample at the comforts of your own home. Once you've collected your saliva in the given tube, all you need to do is send it back to us with the provided prepaid envelope (no extra cost!).",
    },
    {
      question: "Will saliva contain enough DNA?",
      answer:
        "The DNA in saliva originates from cells that are shed from the inner linings of the mouth and from white blood cells. These DNA-containing cells are collected, and the DNA is then extracted by various methods. Research has revealed that salivary DNA is equivalent in quantity and purity to DNA obtained from blood, and that the stability of salivary DNA is good when proper methods of handling are employed.<br/><small>Reference </small><br/><small>Abraham, J.E. et al. (2012). Saliva samples are a viable alternative to blood samples as a source of DNA for high throughput genotyping.</small>",
    },
    {
      question: "How long does it take for me to get my report?",
      answer:
        "There are many steps that we need to take to ensure that we provide the most accurate reports from your DNA, from checking if your saliva sample contain enough DNA, to scanning your DNA for SNPs. The whole process should take about 6–8 weeks once we've received the saliva sample from you.",
    },
    {
      question: "How do I receive my reports?",
      answer:
        "Once your report is ready, you will be notified via email. Your report will be available online through your password-protected account on our platform, which you would have created when you made the purchase.",
    },
    {
      question: "Why do I need to register my kit?",
      answer:
        "In order to tie your saliva sample to your account, you will need to register your kit once you've received the saliva collection kit. You will register the unique barcode on your account, so that we know that the saliva sample belongs to you. Our lab cannot process your saliva sample if it is not registered.",
    },
  ],
}
